<template>
  <section class="hero mt-5">
    <div class="hero-body">
      <div class="container">
        <div class="has-text-centered mb-5 mt-5">
          <h1 class="title has-text-primary has-text-shadow is-size-1">Tal till text</h1>
          <h2 class="subtitle has-text-shadow">Enkel transkribering av ljud på Svenska</h2>
        </div>
        <div class="columns is-centered">
          <div class="column is-6">
            <div class="box has-box-shadow py-1 px-1 has-background-light">
              <div class="content">
                <p>Vi är inte riktigt klara men vi jobbar hårt på att få denna tjänst att fungera. Kom gärna tillbaka snart!</p>

                <h2 class="title is-size-4 mt-1">Vad gör denna tjänst?</h2>
                <p>Transkript.se är en gratistjänst där du kan ladda upp ljud som du vill transkribera till Svenska.</p>
                
                <h2 class="title is-size-4">Så här fungerar det:</h2>
                <ol class="mb-4">
                  <li><span>Ladda upp det ljud du vill transkribera.</span></li>
                  <li><span>Om systemet kan transkribera ljudet får du en unik länk till resultatet.</span></li>
                  <li><span>Länken kommer visa status under tiden systemet jobbar. Därefter visas resultatet.</span></li>
                  <li><span>En timme efter att du laddat ned transkriptet tas det bort från systemet.</span></li>
                </ol>
                <p>Vi använder en egenutvecklad modell för tal till text. Ingen tredje part som Google, Microsoft eller någon annan tar del av din data.</p>
                
                <h2 class="title is-size-4 mt-1">Vilken information sparar ni?</h2>
                <p>Ljudet som ska transkriberas sparas tills det har körts genom systemet. Därefter tas det bort.</p>
                <p>Transkriptet sparas tills det laddats ned men max 24 timmar.</p>
                <p>Ingen information om ljud, transkript eller om dig som användare av tjänsten sparas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.mt-1 {
  margin-top: 1em;
}

.mb-5 {
  margin-top: 5em;
}

.mb-5 {
  margin-bottom: 5em;
}

.has-text-primary {
  color: #363636 !important;
}

.has-text-shadow {
  text-shadow: 1px 1px 2px #8d8d8d;
}

.has-box-shadow {
  box-shadow: 5px 5px 15px #8d8d8d;
}

.has-background-light {
  background-color: #eeeeee !important;
}

li {
  font-size: 2em;
  line-height: 0.75em;
}

li span {
  font-size: 0.5em;
}
</style>

<script>
export default {
  name: "Home",
  data: () => ({
    menuVisible: false
  })
};
</script>