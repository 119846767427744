import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import VueGtag from 'vue-gtag';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import Transcribe from './components/Transcribe'
import HowItWorks from './components/HowItWorks'
import Contact from './components/Contact'

Vue.config.productionTip = false

Vue.use(VueHead)
Vue.use(Buefy)

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Transcribe },
    { path: '/transcribe', component: Transcribe },
    { path: '/howto', component: HowItWorks },
    { path: '/contact', component: Contact }
  ]
})

Vue.use(VueGtag, {
  config: { id: 'UA-18665953-6' }
})

new Vue({
  router,
  render: h => h(App),
  created () {
    const html = document.documentElement
    html.setAttribute('lang', 'sv')
  }
}).$mount('#app')
