<template>
    <div>
        <h1>Hur funkar det?</h1>
        <ol>
            <li>Ladda upp det ljud du vill transkribera.</li>
            <li>Om systemet kan transkribera ljudet får du en unik länk till resultatet.</li>
            <li>Länken kommer visa status under tiden systemet jobbar. Därefter visas resultatet.</li>
            <li>En timme efter att du laddat ned transkriptet tas det bort från systemet.</li>
        </ol>
        <p>Vi använder en egenutvecklad modell för tal till text. Ingen tredje part som Google, Microsoft eller någon annan tar del av din data.</p>
    </div>
</template>

<script>
export default {
    methods: {
    }
}
</script>