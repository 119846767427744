<template>
    <div>
        <h1>Transkribera</h1>
        <p>Vi är inte riktigt klara men vi jobbar hårt på att få denna tjänst att fungera. Kom gärna tillbaka snart!</p>

        <h2>Vad gör denna tjänst?</h2>
        <p>Transkribera.se är en gratistjänst där du kan ladda upp ljud som du vill transkribera till Svenska.</p>
        
        <h2>Vilken information sparar ni?</h2>
        <p>Ljudet som ska transkriberas sparas tills det har körts genom systemet. Därefter tas det bort.</p>
        <p>Transkriptet sparas tills det laddats ned men max 24 timmar.</p>
        <p>Ingen information om ljud, transkript eller om dig som användare av tjänsten sparas.</p>

        <!-- <EmailNotifyForm/>
        <EmailNotifyForm forget="true"/> -->
    </div>
</template>

<script>
//   import EmailNotifyForm from './EmailNotifyForm'
  export default {
    name: 'transcribe',
    // components: {
    //     EmailNotifyForm
    // }
    methods: {
    }
  }
</script>