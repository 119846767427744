<template>
  <div class="bg-img">
    <Navigation />
    <Home />
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
.bg-img {
  // background-image: url("/brandi-redd-aJTiW00qqtI-unsplash.jpg");
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  background-color: #fafafa;
}
</style>

<script>
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
export default {
  name: "app",
  components: {
    Navigation,
    Home,
    Footer
  },
  head: {
    title: {
      inner: "Transkript.se",
      separator: "-",
      complement: "Gratis transkribering"
    },
    meta: [
      { name: "description", content: "Enkel och gratis transkribering av ljud på Svenska." },
      { property: "og:title", content: "Transkript punkt SE" },
      { property: "og:description", content: "Enkel och gratis transkribering av ljud på Svenska." },
      { property: "og:url", content: "https://transkript.se" },
      { property: "og:image", content: "https://transkript.se/logo-black.png" }
    ],
    link: [
      {
        rel: "stylesheet",
        href:
          "//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
      }
    ]
  },
  methods: {}
};
</script>
